@font-face{
    font-family:"Droid Serif W01 Regular";
    src:url("/layout/fonts/b3682a03-bb70-4128-9e3f-47c8254e046c.woff2") format("woff2"),url("/layout/fonts/83ae2051-dcdd-4931-9946-8be747a40d00.woff") format("woff");
}
@font-face{
    font-family:"Droid Serif W01 Italic";
    src:url("/layout/fonts/b4feee07-9b35-4dee-9adc-4b4fab4a967a.woff2") format("woff2"),url("/layout/fonts/c7769089-7fea-48eb-86f7-0c21b0825fa2.woff") format("woff");
}
@font-face{
    font-family:"Droid Serif W01 Bold";
    src:url("/layout/fonts/2ed401c1-0ca9-4c3f-94fb-3c632e45d709.woff2") format("woff2"),url("/layout/fonts/526cd572-c4f4-4a9d-bc31-62a3213edd43.woff") format("woff");
}
@font-face{
    font-family:"DroidSerifW01-BoldItali";
    src:url("/layout/fonts/6430aa9a-1f2a-4872-967c-9fd00b98a9c4.woff2") format("woff2"),url("/layout/fonts/7ca9b56f-bcc5-46b4-9554-18f463796f7d.woff") format("woff");
}
@font-face{
    font-family:"PT Sans W01 Regular";
    src:url("/layout/fonts/a47f6341-0a88-47a8-822e-90dc2c6301db.woff2") format("woff2"),url("/layout/fonts/d8040b31-9fde-4d9e-a2b7-dc14eb7a7e49.woff") format("woff");
}
@font-face{
    font-family:"PT Sans W01 Bold";
    src:url("/layout/fonts/34b1d272-e1e0-46f5-9455-034f3039b481.woff2") format("woff2"),url("/layout/fonts/38cb48c8-2c58-4111-805c-a003efb912c8.woff") format("woff");
}